const isDateLessThanXDays = (days: number, approvedAt?: string): boolean => {
  if (!approvedAt) return false;
  const givenDate = new Date(approvedAt);
  const currentDate = new Date();

  const timeDifference = currentDate.getTime() - givenDate.getTime();

  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return daysDifference >= 0 && daysDifference < days;
};

export default isDateLessThanXDays;
