// @ts-nocheck

import React, { Fragment } from 'react';
import { useMemo } from 'react';
import ReactDOM from 'react-dom';

import * as S from './Tooltip.styles';

import { TooltipPortalProps, TooltipProps } from './types';

function TooltipPortal({
  id,
  effect,
  place,
  title,
  titleElement,
  width,
  offset,
}: TooltipPortalProps) {
  if (typeof document === 'undefined') {
    return null;
  }

  return ReactDOM.createPortal(
    <S.Tooltip
      id={id}
      multiline
      role="tooltip"
      className="tooltip"
      effect={effect}
      place={place}
      arrowColor="transparent"
      offset={offset}
      width={width}
    >
      {title || titleElement}
    </S.Tooltip>,
    document.body
  );
}

export default function Tooltip(props: TooltipProps) {
  const {
    id,
    place = 'top',
    children: childrenProp,
    title,
    effect,
    offset,
    width,
    titleElement,
  } = props;

  const children = useMemo(() => {
    if (
      React.Children.only(childrenProp) &&
      React.isValidElement(childrenProp)
    ) {
      return React.cloneElement(childrenProp, {
        ['data-for']: id,
        ['data-tip']: true,
      });
    }
    return null;
  }, [childrenProp, id]);

  return (
    <Fragment>
      {children}
      <TooltipPortal
        id={id}
        effect={effect}
        place={place}
        title={title}
        titleElement={titleElement}
        width={width}
        offset={offset}
      />
    </Fragment>
  );
}
