import styled, { css } from 'styled-components';

import { AlertColor } from './types';

type AlertProps = {
  color?: AlertColor;
  hasBorder?: boolean;
};

export const Alert = styled.div<AlertProps>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme, color = 'info' }) => theme.colors[color][50]};
  border: ${({ theme, color = 'info', hasBorder }) =>
    hasBorder && `1px solid ${theme.colors[color][500]}`};
  color: ${({ theme, color = 'info' }) => theme.colors[color][500]};
  min-height: ${({ theme }) => theme.spacing[12]};
  border-radius: ${({ theme }) => theme.spacing[2.5]};
  padding: ${({ theme }) => theme.spacing[3]} ${({ theme }) => theme.spacing[5]};

  ${({ color, theme }) =>
    color === 'neutral' &&
    css`
      background-color: ${theme.colors.neutral[200]};
    `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
`;

export const StartIcon = styled.div<AlertProps>`
  flex-shrink: 0;
  margin-right: ${({ theme }) => theme.spacing[2]};
  display: flex;
  align-items: center;
`;

export const Children = styled.div<AlertProps>`
  flex-grow: 1;
  color: ${({ theme, color = 'info' }) => theme.colors[color][500]};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.sm};

  strong {
    font-weight: ${({ theme }) => theme.fontWeight.medium};
  }
  p,
  span,
  strong,
  a {
    margin: 0;
    font-size: ${({ theme }) => theme.fontSize.sm};
  }
`;

export const Button = styled.button<AlertProps>`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background-color: transparent;
  flex-shrink: 0;
  color: ${({ theme, color = 'info' }) => theme.colors[color][500]};
`;
