import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useRouter } from 'next/router';

import { Alert } from '@src/components/ui';

interface AlertWrapperProps {
  children: JSX.Element;
}

function GeneralAlertsWrapper({ children }: AlertWrapperProps) {
  const intl = useIntl();

  const { route } = useRouter();

  const [message, setMessage] = useState('');

  function onClose() {
    setMessage('');
  }

  return (
    <>
      {message && !route.includes('/p/') && (
        <Alert color="warning">{message}</Alert>
      )}
      {children}
    </>
  );
}

export default GeneralAlertsWrapper;
