/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import Storage from 'local-storage-fallback';
import { useLazyQuery } from '@apollo/client';

import * as S from './DepositModal.styles';

import { Header, Icon, Label } from '@ui';
import usePageTrack from '@src/hooks/usePageTrack';
import useSettings from '@src/hooks/useSettings';
import useWallet from '@src/hooks/useWallet';
import { Box, FlexContainer, Grid, Spacer } from '@shared/layouts';
import { CURRENT_BUSINESS_ID } from '@src/constants/storageKeys';
import SHOW_STP_APPLICANT_KYB, {
  ShowSTPApplicantKYBInput,
  ShowSTPApplicantKYBReturn,
} from '@src/graphql/queries/ShowSTPApplicantKYB';
import isDateLessThanXDays from '@src/utils/isDateLessThanXDays';

type ApprovalStatusType = {
  approvalStatus: 'PENDING' | 'REJECTED' | 'APPROVED' | 'IN_PROCESS' | '';
  approvedAt?: string;
  clabeOld?: string;
};

export function DepositModal() {
  usePageTrack('deposit_modal', 'core');
  const { stpClabe } = useWallet();
  const { currentUser, business } = useSettings();
  const currentBusinessName = currentUser?.businesses.find(
    (item) => item.id === Storage.getItem(CURRENT_BUSINESS_ID)
  );
  const currentBusiness = business?.taxPayerInfos?.find(
    (item) => item.taxpayerName === currentBusinessName?.name
  );
  const { formatMessage } = useIntl();
  const [data, setData] = useState<ApprovalStatusType>({ approvalStatus: '' });

  const [getApprovalStatus] = useLazyQuery<
    ShowSTPApplicantKYBReturn,
    ShowSTPApplicantKYBInput
  >(SHOW_STP_APPLICANT_KYB);

  useEffect(() => {
    if (currentBusiness) {
      getApprovalStatus({
        variables: { taxpayerIdentifier: currentBusiness?.taxpayerIdentifier },
        onCompleted: ({ showStpApplicantKyb }) => {
          setData({
            ...showStpApplicantKyb,
          });
        },
        onError: () => {
          setData({ approvalStatus: '' });
        },
      });
    }
  }, []);

  if (!business || !stpClabe) {
    return null;
  }

  return (
    <Box width="128" maxWidth="90vw">
      <Header as="h5">
        {formatMessage({
          defaultMessage: 'Para transferir fondos utiliza los siguientes datos',
          id: 'P5s9Fg',
        })}
      </Header>
      <Spacer margin="5" />
      <Grid container>
        <S.NewCLABE
          hasNewCLABE={
            data.approvalStatus === 'APPROVED' &&
            isDateLessThanXDays(30, data?.approvedAt)
          }
        >
          {data.approvalStatus === 'APPROVED' &&
            isDateLessThanXDays(30, data?.approvedAt) && (
              <S.Chip> Nueva CLABE</S.Chip>
            )}
          <FlexContainer fullWidth>
            <Grid item xs={6}>
              <Label size="lg" strong>
                {formatMessage({
                  defaultMessage: 'CLABE:',
                  id: '2X1Nna',
                })}
              </Label>
            </Grid>
            <FlexContainer alignContent="center">{stpClabe}</FlexContainer>
          </FlexContainer>
        </S.NewCLABE>
        <S.Wrapper>
          <FlexContainer>
            <Grid item xs={6}>
              <Label size="lg" strong>
                {formatMessage({
                  defaultMessage: 'Banco:',
                  id: 'W9QYB9',
                })}
              </Label>
            </Grid>
            <Grid item xs={6}>
              <p>STP</p>
            </Grid>
          </FlexContainer>
          <FlexContainer>
            <Grid item xs={6}>
              <Label size="lg" strong>
                {formatMessage({
                  defaultMessage: 'Nombre:',
                  id: 'biswPx',
                })}
              </Label>
            </Grid>
            <Grid item xs={6}>
              <p>{business?.taxPayerInfos?.[0]?.taxpayerName}</p>
            </Grid>
          </FlexContainer>
        </S.Wrapper>
      </Grid>
      <Spacer margin="6" />
      {/* <S.TransferInfo>
        {formatMessage({
          defaultMessage:
            'Contáctanos para cualquier duda o problema que tengas.',
          id: '04pwGR',
        })}
      </S.TransferInfo> */}
      {data.approvalStatus === 'APPROVED' &&
        isDateLessThanXDays(30, data?.approvedAt) &&
        data.clabeOld && (
          <S.OldCLABE>
            <Icon name="Info" variant="solid" size={50} />
            <>
              {`Estos son los datos de tu antigua cuenta STP: ${data.clabeOld}. 
Todos los pagos realizados a tu antigua cuenta, serán devueltos a la cuenta de origen. `}
            </>
          </S.OldCLABE>
        )}
    </Box>
  );
}

DepositModal.displayName = 'DepositModal';

export default DepositModal;
