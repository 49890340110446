import { useEffect } from 'react';
import { useRouter } from 'next/router';

import useSettings from '@src/hooks/useSettings';
import { loggedIn } from '@src/lib/auth';

function Index() {
  const router = useRouter();
  const { business, currentUser } = useSettings();
  const { invitationToken } = useSettings();

  useEffect(() => {
    if (loggedIn()) {
      if (currentUser && !business) {
        router.replace('/onboarding');
      } else {
        router.replace('/wallet');
      }
    } else {
      router.replace('/p/login');
    }
  }, [currentUser]);

  return null;
}

export default Index;
